/* import __COLOCATED_TEMPLATE__ from './metrics-table.hbs'; */
/* RESPONSIBLE TEAM: team-ai-insights */
import Component from '@glimmer/component';
import { use } from 'ember-resources/util/function-resource';
import type { ReportState } from 'embercom/components/reporting/custom/report/chart-card';
import { groupBy } from 'underscore';
import { staticDataResource } from 'embercom/lib/reporting/static-data-resource';
import type { Topic } from './topics-data-resources';
import { inject as service } from '@ember/service';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import { action } from '@ember/object';
import { cached } from 'tracked-toolbox';
import type {
  Job,
  JobId,
} from 'embercom/components/reporting/automation/ai-insights/fin-insights/jobs';
import type IntlService from 'ember-intl/services/intl';
import { tracked } from '@glimmer/tracking';
import { TrackedObject } from 'tracked-built-ins';
import { type MetricValue } from 'embercom/lib/reporting/chart-data-resource';
import { isPresent } from '@ember/utils';
import { and } from 'embercom/components/reporting/automation/ai-insights/funnel-config';
import { formatTableValue } from 'embercom/lib/reporting/custom/view-config-builder-helpers';
import { shouldConvertNullsToZeros } from 'embercom/lib/reporting/flexible/data-response-helpers';
import { type FieldMetric } from 'embercom/objects/reporting/unified/metrics/types';
import type { ChartSeriesDefinition } from 'embercom/components/reporting/automation/ai-insights/fin-insights/jobs';

interface Signature {
  Args: {
    currentTopic: Topic | null;
    selectedJob: Job;
    reportState: ReportState;
    onClickNavigateToTopic: (topicId: string) => void;
    onShowConversationExplorer: (
      topic: string,
      subtopic: string | null,
      type: 'suggestions' | 'conversations',
      metrics: Record<string, string> | undefined,
    ) => void;
  };
}

interface SortState {
  valuePath: string;
  direction: 'asc' | 'desc';
}

interface RowData {
  key: string;
  metricValues: MetricValue[];
  subtopicData: {
    topicId: string;
    key: string;
    metricValues: MetricValue[];
  }[];
}

interface Column {
  label: string;
  valuePath: string;
  isSortable?: boolean;
  chartSeries?: ChartSeriesDefinition;
  tooltip?: string;
}

export default class MetricsTable extends Component<Signature> {
  @service declare reportingMetrics: ReportingMetrics;
  @service declare intl: IntlService;

  @use topicsData = staticDataResource(this, {
    serializedChart: () => this.topicsChartDefinition,
    reportState: () => this.args.reportState,
  });
  @use subtopicsData = staticDataResource(this, {
    serializedChart: () => this.subtopicsChartDefinition,
    reportState: () => this.args.reportState,
  });

  sortStates = new TrackedObject<Record<JobId, SortState>>();

  @tracked expandedRow: string | null = null;

  get topicsChartDefinition() {
    return {
      view_by: 'conversation_custom_fields#ai_insights_topic',
      view_by_display_limit: 5000,
      view_by_other: false,
      chart_series: this.args.selectedJob.table.chartSeries.map(
        (series: ChartSeriesDefinition) => ({
          ...series,
          filters: and(this.args.selectedJob.table.filters, series.filters),
        }),
      ),
      visualization_type: 'bespoke',
    };
  }

  get subtopicsChartDefinition() {
    return {
      ...this.topicsChartDefinition,
      view_by: 'conversation_custom_fields#ai_insights_topic_subtopic',
    };
  }

  get subtopicMetricsByTopic() {
    return groupBy(
      Object.entries(this.subtopicsData.flatCombinedData),
      ([key]) => key.split('=>')[0],
    );
  }

  @cached
  get subtopicCountsByTopic() {
    return Object.fromEntries(
      Object.entries(this.subtopicMetricsByTopic).map(([topic, metrics]) => [
        topic,
        metrics.length,
      ]),
    );
  }

  get metrics() {
    return this.args.selectedJob.table.chartSeries.map((series) =>
      this.reportingMetrics.getMetricById(series.metric_id),
    );
  }

  get columns(): Column[] {
    let viewByLabel = this.intl.t(
      'components.reporting.automation.ai-insights.fin-insights.metrics-table.topic-header',
    );
    return [
      { label: viewByLabel, valuePath: 'key', isSortable: true },
      ...this.args.selectedJob.table.chartSeries.map((series, index) => ({
        label: series.label || this.metrics[index].name,
        valuePath: `metricValues.${index}`,
        tooltip: series.tooltip,
        isSortable: true,
        chartSeries: series,
      })),
      {
        label: this.intl.t(
          'components.reporting.automation.ai-insights.fin-insights.metrics-table.suggestions-header',
        ),
        valuePath: 'suggestions',
      },
    ];
  }

  get rows(): RowData[] {
    let subTopicDataArray = Object.entries(this.subtopicsData.flatCombinedData).map(
      ([key, metricValues]) => {
        let [topicId, subtopicId] = key.split('=>');
        return {
          topicId,
          key: subtopicId,
          metricValues,
        };
      },
    );
    let subtopicData = groupBy(subTopicDataArray, 'topicId');
    return Object.entries(this.topicsData.flatCombinedData).map(([key, metricValues]) => {
      return { key, metricValues, subtopicData: subtopicData[key] };
    });
  }

  get sortState() {
    return (
      this.sortStates[this.args.selectedJob.id] || {
        valuePath: this.columns[this.args.selectedJob.table.defaultSortColumn].valuePath,
        direction: 'desc',
      }
    );
  }

  set sortState(sortState: SortState) {
    this.sortStates[this.args.selectedJob.id] = sortState;
  }

  get sortedRows() {
    let columnIndex = this.columns.findIndex(
      (column) => column.valuePath === this.sortState.valuePath,
    );
    let data = [...this.rows];
    let sortAscending = this.sortState.direction === 'asc';
    let sortFunction = (a: any, b: any) => {
      if (columnIndex === 0) {
        // sorting by topic or subtopic name
        return sortAscending ? a.key.localeCompare(b.key) : b.key.localeCompare(a.key);
      } else {
        // sorting by a metric value
        let aValue = this.valueForSorting(a.metricValues[columnIndex - 1]);
        let bValue = this.valueForSorting(b.metricValues[columnIndex - 1]);
        return sortAscending ? aValue - bValue : bValue - aValue;
      }
    };
    data.sort(sortFunction);
    // sort the subtopicData for each topic
    data.forEach((row) => {
      row.subtopicData.sort(sortFunction);
    });
    return data;
  }

  valueForSorting(value: MetricValue): number {
    if (typeof value === 'number') {
      return value;
    } else if (isPresent(value) && isPresent(value.value)) {
      return value.value!;
    } else {
      // sort null values to the bottom
      return -Number.MAX_VALUE;
    }
  }

  @action
  onSort(sortValuePath: string) {
    let direction: SortState['direction'] = 'desc';
    if (this.sortState.valuePath === sortValuePath) {
      // toggle the direction
      direction = this.sortState.direction === 'asc' ? 'desc' : 'asc';
    }

    this.sortState = {
      valuePath: sortValuePath,
      direction,
    };
  }

  get isLoading() {
    // resources are lazily loaded, so this next bit is a hack to make sure that
    // we eagerly get the subtopic data without waiting for the topics rows to be rendered.
    this.subtopicsData.isLoading;
    this.topicsData.isLoading;
    return this.subtopicsData.isLoading || this.topicsData.isLoading;
  }

  @action
  expandCollapseRow(topicId: string) {
    this.expandedRow = this.expandedRow === topicId ? null : topicId;
  }

  @action
  getMetricsForRow(rowData: { metricValues: MetricValue[] }): Record<string, string> {
    return this.args.selectedJob.table.chartSeries.reduce((acc, chartSeries, index) => {
      let rawValue: MetricValue = rowData.metricValues[index];
      let formattedValue = this.formatMetricValue(chartSeries, rawValue);
      return {
        ...acc,
        [this.columns[index + 1].label]: formattedValue,
      };
    }, {});
  }

  @action
  formatMetricValue(chartSeries: ChartSeriesDefinition, rawValue: MetricValue) {
    let metric = this.reportingMetrics.getMetricById(chartSeries.metric_id);
    let numericValue = this.numericValue(rawValue);
    let value = formatTableValue(numericValue, metric.unit);

    let aggregation = chartSeries.aggregation || (metric as FieldMetric).defaultAggregation;
    let defaultValue = shouldConvertNullsToZeros(aggregation, metric as any) ? 0 : '-';
    return value || defaultValue;
  }

  numericValue(value: MetricValue | null): number | null {
    if (typeof value === 'number') {
      return value;
    }
    return value?.value ?? null;
  }

  @action
  onClickExplore(
    topicId: string,
    subtopicId: string | null,
    rowData: { metricValues: MetricValue[] },
    type: 'suggestions' | 'conversations',
  ) {
    this.args.onShowConversationExplorer(topicId, subtopicId, type, this.getMetricsForRow(rowData));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Automation::AiInsights::FinInsights::MetricsTable': typeof MetricsTable;
  }
}
