/* import __COLOCATED_TEMPLATE__ from './suggestion-button.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type SuggestionService from 'embercom/services/suggestion-service';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  topicId: string;
  subTopicId: string | null;
  onClick: () => void;
}
export default class SuggestionButton extends Component<Args> {
  @service declare suggestionService: SuggestionService;
  @service declare intl: IntlService;

  get topicsSuggestionCount() {
    if (this.args.subTopicId) {
      // If subTopicId is set, then we need to get the count for the subtopics grouped by the topicId
      return (
        this.suggestionService.topicsSummary
          .get(this.args.topicId)
          ?.subtopics.find((subtopic) => subtopic.name === this.args.subTopicId)?.count ?? 0
      );
    }
    return this.suggestionService.topicsSummary.get(this.args.topicId)?.totalCount ?? 0;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Automation::AiInsights::FinInsights::SuggestionButton': typeof SuggestionButton;
  }
}
